import { gql } from 'urql'

const QueryTenantReferralAgencySettingShow = gql`
  query {
    tenantReferralSettingShow {
      id
      values
      attachments {
        name
        id
        file
        blobs
      }
    }
  }
`

export default QueryTenantReferralAgencySettingShow
