import { cva } from 'class-variance-authority'
import Link from 'next/link'
import { parseCookies } from 'nookies'
import { FC, ReactNode } from 'react'
import { SESSION_COOKIE_IS_DISABLE_SIDEBAR_ITEM } from '~/core/constants/cookies'
import { Badge } from '~/core/ui/Badge'

interface SidebarItemProps {
  icon?: ReactNode
  title?: string
  badge?: number
  selected?: boolean
  link?: string
  isDropdownMenu?: boolean
  noLink?: boolean
  disabled?: boolean
  onClick?: () => void
  openNav?: boolean
}

const SidebarItem: FC<SidebarItemProps> = ({
  icon,
  title,
  badge = 0,
  selected = false,
  link = '',
  isDropdownMenu = false,
  noLink = false,
  onClick,
  disabled = false,
  openNav = false
}) => {
  const cookies = parseCookies()
  let disabledItem = (cookies[SESSION_COOKIE_IS_DISABLE_SIDEBAR_ITEM] ||
    disabled ||
    false) as boolean

  const linkVariants = cva(
    'group dark:bg-gray-900 flex items-center rounded cursor-pointer font-medium px-[11px]',
    {
      variants: {
        selected: {
          default:
            'text-gray-600 hover:text-gray-800 hover:bg-gray-50 dark:hover:bg-gray-800',
          selected: 'bg-gray-100 dark:bg-gray-700 text-gray-800'
        },
        dropdown: {
          default: `${openNav ? 'w-full' : 'w-10 justify-center'} h-8`,
          dropdown: 'px-4 py-[10px]'
        },
        disabled: {
          default: '',
          disabled: 'pointer-events-none opacity-50'
        }
      },
      defaultVariants: {
        selected: 'default',
        dropdown: 'default'
      }
    }
  )

  const renderHTML = () => {
    if (!openNav) {
      if (badge) {
        return (
          <div className="relative">
            {icon}
            <div className="absolute -top-[10px] left-[3px]">
              <Badge
                startPlus={9}
                size="xs"
                type="notification"
                badgeCount={badge}
              />
            </div>
          </div>
        )
      }
      return icon
    } else {
      return (
        <>
          <div>{icon}</div>
          <div className="ml-3 flex items-center whitespace-nowrap">
            <div className="text-sm font-medium text-gray-700">{title}</div>
            {!!badge && (
              <div className="ml-1">
                <Badge
                  startPlus={9}
                  type="notification"
                  size="xs"
                  badgeCount={badge}
                />
              </div>
            )}
          </div>
        </>
      )
    }
  }

  if (noLink) {
    return (
      <div
        className={linkVariants({
          selected: selected ? 'selected' : 'default',
          dropdown: isDropdownMenu ? 'dropdown' : 'default',
          disabled: disabledItem ? 'disabled' : 'default'
        })}
        onClick={() => onClick && onClick()}>
        {renderHTML()}
      </div>
    )
  }

  return (
    <Link
      href={link}
      className={linkVariants({
        selected: selected ? 'selected' : 'default',
        dropdown: isDropdownMenu ? 'dropdown' : 'default',
        disabled: disabledItem ? 'disabled' : 'default'
      })}>
      {renderHTML()}
    </Link>
  )
}

export default SidebarItem
